<template>
  <div class="loading">
    Loading ...
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loading{
  margin: 10rem auto;
}

</style>